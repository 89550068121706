/**
 * Formats a price to a string with the correct currency symbol and decimal separator.
 * Using the Intl.NumberFormat API. 
 * 
 * Read more about Intl.NumberFormat:  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * 
 * @param price The number to format
 * @param currencyCode The currency code to use 
 * @param countryCode The country code to use or undefined to use the default
 * @param settings The settings to use
 * 
 * @returns String 
 * 
 * @example
 * 
 * priceFormatter(100, 'EUR', 'se-SE')
 * // => '100,00 €'
 * 
 * priceFormatter(1234.123, 'EUR', 'se-SE')
 * // => '1 234,12 €'
 * 
 * priceFormatter(0, 'EUR', 'en-GB', { decimals: 0 })
 * // => '€0'
 * 
 * priceFormatter(0, 'SEK', 'se-SE', { symbol: false })
 * // => '0,00'
 * 
 * priceFormatter(0, 'SEK', 'se-SE', { currencyDisplay: 'code' })
 * // => '0,00 SEK'
 * 
 * priceFormatter(0, 'SEK', 'se-SE', { currencyDisplay: 'name' })
 * // => '0,00 Swedish krona'
 * 
 */

export interface PriceFormatterSettings {
	decimals?: number
	common?: boolean
	symbol?: boolean
	format?: boolean
	currencyDisplay?: 'symbol' | 'code' | 'name'
	zeroValueLine?: boolean
}

const defaultPriceFormatterSettings: PriceFormatterSettings = {
	decimals: 2,
	common: true,
	symbol: true,
	format: true,
	zeroValueLine: false,
	currencyDisplay: 'symbol',
}

export const priceFormatter = (
	price: number | null | undefined,
	currencyCode: string,
	countryCode: string | undefined = undefined,
	settings: PriceFormatterSettings = {}
): string | number => {
	try {
		const { 
			symbol,
			format,
			decimals,
			zeroValueLine,
			currencyDisplay
		} = {
			...defaultPriceFormatterSettings,
			...settings,
		}
		
		if (price == null) {
			throw new Error('Price is null or undefined')
		}

		if (isNaN(price)) {
			throw new Error('Price is not a number')
		}

		if (typeof price !== 'number') {
			throw new Error('Price is not a number')
		}
		
		if (currencyCode == null) {
			throw new Error('Currency code is null or undefined')
		}
		
		if (typeof currencyCode !== 'string') {
			throw new Error('Currency code is not a string')
		}
		
		if (currencyCode.length !== 3) {
			throw new Error('Currency code is not 3 characters')
		}
		
		if (!format) {
			return price
		}	
		
		if (price === 0 && zeroValueLine) {
			return '-'
		}
		
		// Make sure that the country code is lowercase when its 2 characters
		const country = (typeof countryCode === 'string' && countryCode.length === 2) ? 
			countryCode.toLowerCase() : 
			(typeof countryCode === 'undefined') ? 
				undefined : countryCode

		// Get the financial format for the price
		const getBaseFormat = new Intl.NumberFormat(country , {
			style: (symbol) ? 'currency' : 'decimal',
			currency: currencyCode,
			currencyDisplay: currencyDisplay,
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		}).format(price)
		
		// Replace the grouping separator with a space
		const matches = getBaseFormat.match(/[.,]/g)!
		
		let formattedPrice = ''
		// We will always have a point
		if (matches?.length > 0) {
			const point = matches[matches?.length - 1]
			// We might not have a grouping separator so we go depending on the point character
			const groupingSeparator = (point === '.') ? ',' : '.'
			// Replace the grouping separator with a space
			formattedPrice = getBaseFormat.replace(new RegExp(`\\${groupingSeparator}`, 'g'), ' ')
		} else {
			formattedPrice = getBaseFormat
		}

		// Make sure that spaces are actually spaces and not non-breaking spaces
		return formattedPrice.replace(/\xa0/g, ' ')
		
	}	catch (error) {
		return '-'
	}
} 

export default priceFormatter