import { Filter } from '@barnsten-it/gummidata-react-components'
import { isNettoVip } from 'utils/helper'

interface CommonFilterSetting {
	name?: string
	group?: CommonFilterSetting[]
	property?: string
	type?: any
	condition?: any
}

export const commonFilterSettings = (t: any, articles: any[]) => {
	let hasOutlet = articles.some(a => a.isOutlet)
	let hasNettoVip = articles.some(a => isNettoVip(a.subGroupId))
	let filter: CommonFilterSetting = {}
	

	if(hasOutlet || hasNettoVip){
		filter = {
			name: t('searchFilter.campaign'),
			group: [],
		}
	}

	if(hasOutlet){
		filter.group?.push({
			name: t('searchFilter.outlet'),
			property: 'isOutlet',
			type: Filter.type.checkbox,
		})
	}

	
	if(hasNettoVip){
		filter.group?.push({
			name: t('searchFilter.vip'),
			property: 'subGroupId',
			type: Filter.type.checkbox,
			condition: (item: any) => isNettoVip(item.subGroupId)
		})
	}

	return filter
}