/// <reference types="./globals.d.ts" /> 

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

// import the app
import App from './app/App'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { BrowserTracing } from '@sentry/tracing'
import {
	BrowserRouter,
	useLocation,
} from 'react-router-dom'
import 'utils/polyfills'
import './app/App.scss'
import store from './app/store'
import reportWebVitals from './reportWebVitals'

/**
 * @sentry/react init
 */
if (process.env['NODE_ENV'] !== 'development') {
	Sentry.init({
		dsn: 'https://55e50ee30f8d4904a30b6a721ddce0ff@o558011.ingest.sentry.io/6100322',
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(
					useEffect,
					// @ts-ignore
					useLocation,
				),
			}),
		],
		normalizeDepth: 10,
		release: `gummidata-webapp@${process.env['REACT_APP_VERSION']}`,
		tracesSampleRate: 0.1,
		//debug: true,
	})

	datadogRum.init({
		applicationId: '960d3024-99c4-4787-88ca-fb3362c8acfc',
		clientToken: 'pub29a1f8cbbb4a1ac9e49a19904e139a9f',
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.eu',
		service: 'gummidata-webapp',
		env: process.env['NODE_ENV'],
		// Specify a version number to identify the deployed version of your application in Datadog
		version: process.env['REACT_APP_VERSION'],
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		defaultPrivacyLevel: 'mask-user-input',
	})
	
	datadogLogs.init({
		clientToken: 'pub29a1f8cbbb4a1ac9e49a19904e139a9f',
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.eu',
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
		env: process.env['NODE_ENV'],
		service: 'gummidata-webapp',
		version: process.env['REACT_APP_VERSION'],
	})

}

// const SentryRoutes = Sentry.withSentryRouting(Routes)

/**
 * App
 */
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
